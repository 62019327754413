import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  MessageOutlined,
  LineChartOutlined,
  ReadOutlined,
  ShopOutlined,
  PictureOutlined,
  SolutionOutlined,
  GiftOutlined,
  ShoppingOutlined,
  WalletOutlined,
  PartitionOutlined,
  DollarCircleOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/dashboard" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [

  {
    exact: true,
    path: "/dashboard",
    sidebar: {
      icon: <LineChartOutlined />,
      label: "대시보드",
    },
    component: loadable(() => import("../pages/dashboard/Dashboard")),
  },
  {
    exact: true,
    path: "/store",
    sidebar: {
      icon: <ShopOutlined />,
      label: "매장 관리",
    },
    component: loadable(() => import("../pages/store/List")),
  },
  {
    exact: true,
    path: "/menu",
    sidebar: {
      icon: <ShoppingOutlined />,
      label: "메뉴 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "메뉴 목록",
        },
        component: loadable(() => import("../pages/menu/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/menu/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/menu/Detail")),
      },
    ],
  },
  {
    exact: true,
    path: "/gift",
    sidebar: {
      icon: <GiftOutlined />,
      label: "상품권 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "전체 상품권 관리",
        },
        component: loadable(() => import("../pages/gift/List")),
      },
    ],
  },
  {
    exact: true,
    path: "/calculate",
    sidebar: {
      icon: <DollarCircleOutlined />,
      label: "정산 관리",
    },
    children: [
      {
        exact: true,
        path: "",
        sidebar: {
          label: "정산 리스트",
        },
        component: loadable(() => import("../pages/calculate/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/calculate/Detail")),
      },
    ],
  },
];
